$(function ()  {

    if($('.slider__carousel').length > 0) {
        slider();
    }

    if($('.catalog__schem').length > 0) {
        catHover();
        elementHover();
    }

    if($('.exampl__carousel').length > 0) {
        exampl();
    }

    if($('.sertificat__carousel').length > 0) {
        sertificate();
    }

    if($('.sidebar__filters').length > 0) {
        filters()
    }

    if($('.preview__carousel').length > 0) {
        previews()
    }

    if($('.similar__carousel').length > 0) {
        similar()
    }

    if($('.sertificatonly__carousel').length > 0) {
        sertificatonly();
    }

    if($('.popular__carousel').length > 0) {
        popular()
    }

    if($('.projrct__carousel').length > 0) {
        projrct()
    }

    if($('.sertificfour__carousel').length > 0) {
        sertificfour()
    }


    $('.sub__inner a').hover(function () {
       var
           thisImg = $(this).data().img;

       $('.sub__img img').attr('src', thisImg);
    });

});


//Слайдер на першому блоці
function slider() {
    var owl = $(".slider__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        items: 1,
        addClassActive : true,
        dotsSpeed: 500,
        autoplayTimeout: 7000,
        loop: true,
        touchDrag: true,
        autoplay: true,
        mouseDrag: true,
        stagePadding: 0,
        responsive : {
            0 : {
                nav: false
            },
            500 : {
                nav: true
            }
        }
    });
}


//Зміна кольору продукції в каталозі
function colorChange($this) {
    event.preventDefault();

    var
        thisImg = $this.data().color;

    $('.catalog__image img').attr('src', thisImg);
}


//Наведення на категорії в каталозі
function catHover() {

    $('.hover__block').each(function () {
        var
            thisCat = $(this).data().kind,
            thisLinl = $('.catalog__link').filter('[data-cat="'+thisCat+'"]'),
            catLink = thisLinl.attr('href');

        $(this).attr('href', catLink);
    });

    $('.catalog__link').mouseenter(function () {
        var
            $this = $(this),
            thisCat = $this.data().cat,
            thisParts = $('.hover__block').filter('[data-kind="'+thisCat+'"]');

        thisParts.addClass('hovered');
    });

    $('.catalog__link').mouseleave(function () {

       $('.hover__block').removeClass('hovered');

    });
}


//Наведення на елементи стічної системи
function elementHover() {
    $('.hover__block').mouseenter(function () {
        var
            $this = $(this),
            thisCat = $this.data().kind,
            thisParts = $('.catalog__link').filter('[data-cat="'+thisCat+'"]');

        thisParts.addClass('hovered');
    });

    $('.hover__block').mouseleave(function () {

        $('.catalog__link').removeClass('hovered');

    });
}


//Слайдер з прикладами робіт
function exampl() {
    var owl = $(".exampl__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                margin: 8,
                nav: false,
                items: 3
            },
            500 : {
                margin: 15,
                nav: true
            },
            900 : {
                nav: true,
                margin: 15,
                items: 4
            }
        }
    });
}


//Слайдер з сертифікатами
function sertificate() {
    var owl = $(".sertificat__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                margin: 8,
                nav: false,
                items: 3
            },
            500 : {
                margin: 10,
                nav: true,
                items: 2
            },
            900 : {
                margin: 11,
                nav: true,
                items: 3
            }
        }
    });
}


//Диллери
function initMap() {
    var myLatLng = {lat: 50.4494541, lng: 30.5995664};
    var image = '../img/icons/marker.png';

    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: myLatLng
    });

    var marker1 = new google.maps.Marker({
        position: {lat: 50.4494541, lng: 30.5995664},
        map: map,
        title: 'Бизнес Гранд Украина',
        icon: image
    });

    var marker2 = new google.maps.Marker({
        position: {lat: 50.4894541, lng: 30.5115664},
        map: map,
        title: 'Бизнес Гранд Украина',
        icon: image
    });
}


//Акордеон з фільтрами
function filters() {
    $('.filter__top').each(function () {
        var
            $this = $(this),
            thisItem = $this.closest('.filter__kind'),
            thisContent = thisItem.find('.filter__content'),
            thisInner = thisContent.find('.filter__inner'),
            thisHeight = thisInner.outerHeight();

        if($this.hasClass('openedAcc')) {
            thisContent.height(thisHeight);
        } else {
            thisContent.height(0);
        }
    });

    $('.filter__top').click(function (e) {
        e.preventDefault()
        var
            $this = $(this),
            thisItem = $this.closest('.filter__kind'),
            thisContent = thisItem.find('.filter__content'),
            thisInner = thisContent.find('.filter__inner'),
            thisHeight = thisInner.outerHeight();

        if(!$this.hasClass('openedAcc')) {
            $this.addClass('openedAcc');
            thisContent.css({'height' : thisHeight});
        } else {
            $this.removeClass('openedAcc');
            thisContent.css({'height' : '0'});
        }
    });
}



//Слайдер з прев'ю на товарі
function previews() {
    var owl = $(".preview__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        items: 3,
        autoplay: false,
        addClassActive : true,
        loop: true,
        nav: false,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                margin: 18
            },
            500 : {
                margin: 11
            },
            900 : {
                margin: 16
            }
        }
    });
}


//Підгрузка прев'ю до головного
function previreProd($this) {
    event.preventDefault();

    var
        thisImg = $this.find('img').attr('src');

    $('.product__prevlink').removeClass('active');
    $this.addClass('active');
    $('.product__preview img').attr('src', thisImg);
}


//Віднімання і додавання кількості товару
function inputMinus() {
    var
        numVal = parseInt($('.pm__input').val());

    if(numVal > 1) {
        $('.pm__input').val(numVal - 1)
    } else {
        $('.pm__input').val(1)
    }
}
function inputPlus() {
    var
        numVal = parseInt($('.pm__input').val());

    if(numVal > 0) {
        $('.pm__input').val(numVal + 1)
    } else {
        $('.pm__input').val(1)
    }
}


//Слайдер з рекомендованими товарами
function similar() {
    var owl = $(".similar__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        nav: false,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                items: 2,
                margin: 15
            },
            500 : {
                items: 3,
                margin: 28
            },
            900 : {
                items: 5,
                margin: 22
            }
        }
    });
}


//Слайдер з сертифікатами (лише сертифікати)
function sertificatonly() {
    var owl = $(".sertificatonly__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        nav: true,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                margin: 8,
                nav: false,
                items: 3
            },
            500 : {
                margin: 14,
                nav: true,
                items: 3
            },
            900 : {
                margin: 11,
                items: 5,
            }
        }
    });
}


//Слайдер з популярними товарами
function popular() {
    var owl = $(".popular__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0 : {
                items: 2,
                nav: false,
                margin: 15
            },
            500 : {
                items: 3,
                nav: true,
                margin: 14
            },
            900 : {
                items: 5,
                nav: true,
                margin: 22
            }
        }
    });
}


//Слайдер на першому блоці сторынки проекту
function projrct() {
    var owl = $(".projrct__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        items: 1,
        autoplay: true,
        addClassActive : true,
        loop: true,
        nav: true,
        touchDrag: true,
        dotsSpeed: 500,
        autoplayTimeout: 7000,
        stagePadding: 0
    });
}


//Наведення на іконки поширення
function shereHover($this) {
    var
        thisColor = $this.data().color;

    $this.css({'background-color' : thisColor, 'border-color' : thisColor});
}
function shereUnhover($this) {

    $this.css({'background-color' : '#fff', 'border-color' : '#F0F0F0'});
}


//Слайдер з сертифікатами (4 сертифікати)
function sertificfour() {
    var owl = $(".sertificfour__carousel").owlCarousel({
        smartSpeed: 500,
        dots: false,
        mouseDrag: true,
        autoplay: false,
        addClassActive : true,
        loop: true,
        nav: true,
        touchDrag: true,
        dotsSpeed: 500,
        stagePadding: 0,
        responsive : {
            0: {
                margin: 8,
                nav: false,
                items: 3
            },
            500: {
                margin: 12,
                nav: true,
                items: 3
            },
            900: {
                margin: 11,
                items: 4,
            }
        }
    });
}


//Відкриття попапів
function openPopup($this) {
    event.preventDefault();

    var
        thisHref = $this.attr('href'),
        popup = $(thisHref);

    $('body').addClass('overHidden');
    popup.fadeIn(400);

    if($this.hasClass('openImg')) {
        var
            thisSrc = $this.data().img;

        $('.popup__img img').attr('src', thisSrc);
    }
}

function closePopup() {
    event.preventDefault();

    $('body').removeClass('overHidden');
    $('.popup').fadeOut(400);
}


//Рейтинг в попапі відгуку
function statRat($this) {
    var
        prevLabels = $this.prevAll('label'),
        nextLabels = $this.nextAll('label');

    prevLabels.addClass('starY');
    nextLabels.removeClass('starY');
}
function statHov($this) {
    var
        prevLabels = $this.prevAll('label'),
        nextLabels = $this.nextAll('label');

    prevLabels.addClass('starHov');
    prevLabels.removeClass('starNo');
    $this.removeClass('starNo');
    nextLabels.removeClass('starHov');
    nextLabels.addClass('starNo');
}
function starLeav() {
    $('.starHov').removeClass('starHov');
    $('.starNo').removeClass('starNo');
}